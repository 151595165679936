import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";
const apiNamespace = "Nationalities";


const getAllNationalitiesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_NATIONALITIES,
});
const addNationalityLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_NATIONALITY,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

const editNationalityLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_NATIONALITY,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deleteNationalityLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_NATIONALITY,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});



export default [
  getAllNationalitiesLogic,
  addNationalityLogic,
  editNationalityLogic,
  deleteNationalityLogic,

];
