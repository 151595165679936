import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";

const apiNamespace = "refusalReasons";

const getRefusalReasonsLogic = logic(apiNamespace, {
    actionName: ActionTypes.GET_REFUSAL_REASONS,
});

const getRefusalReasonWithId = logic(apiNamespace, {
    actionName: ActionTypes.GET_REFUSAL_REASON_WITH_ID,
});
const addRefusalReasonLogic = logic(apiNamespace, {
    actionName: ActionTypes.ADD_REFUSAL_REASON,
    showErrorMessage: true,
    errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
    showSuccessMessage: true,
    successMessage: <Trans id="item_added_successfully" />,
});

const editRefusalReasonLogic = logic(apiNamespace, {
    actionName: ActionTypes.EDIT_REFUSAL_REASON,
    showErrorMessage: true,
    errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
    showSuccessMessage: true,
    successMessage: <Trans id="item_edited_successfully" />,
});

const deleteRefusalReasonLogic = logic(apiNamespace, {
    actionName: ActionTypes.DELETE_REFUSAL_REASON,
    showErrorMessage: true,
    errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
    showSuccessMessage: true,
    successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
    getRefusalReasonsLogic,
    addRefusalReasonLogic,
    editRefusalReasonLogic,
    deleteRefusalReasonLogic,
    getRefusalReasonWithId,
];
