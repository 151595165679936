import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";

const apiNamespace = "attendances";

const getAllAttendancesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_ATTENDANCES,
});


export default [getAllAttendancesLogic];
