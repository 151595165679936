import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllAttendances: { args: ["payload"], meta: { async: true } },
  },
  {
    prefix: "@app/Attendances/",
  },
);
export const ActionTypes = Types;
export default Creators;
