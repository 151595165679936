export default function (/**@type {ApisauceInstance} */ api) {


    const getAllShiftSessions = (paging) => api.get(`Session`, paging);
    const getMonthlyReports = (paging) => api.get(`Session/monthly-reports`, paging);
    const getExportedSessions = (payload) => api.get(`survey/client-response-to-excel`, payload);
    const getShiftSessionWithId = (id) => api.get(`Session/shift-session-with-id/${id}`);

    return {
        shiftSessions: {
            getAllShiftSessions,
            getShiftSessionWithId,
            getMonthlyReports,
            getExportedSessions
        },
    };
}
