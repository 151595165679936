import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  attendances: [],
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllAttendances",
    stateName: "attendances",
    async: true,
    isPaginated: true,
  }),
});
