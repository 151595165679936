import { Trans } from "@lingui/react";
import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";

const apiNamespace = "prizes";

const getAllPrizesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_PRIZES,
});
const addPrizeLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_PRIZE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

const editPrizeLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_PRIZE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deletePrizeLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_PRIZE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

const getAllOutletPrizesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_OUTLET_PRIZES,
});
const addOutletPrizeLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_OUTLET_PRIZE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

const editOutletPrizeLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_OUTLET_PRIZE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deleteOutletPrizeLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_OUTLET_PRIZE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllPrizesLogic,
  addPrizeLogic,
  editPrizeLogic,
  deletePrizeLogic,
  //////
  getAllOutletPrizesLogic,
  addOutletPrizeLogic,
  editOutletPrizeLogic,
  deleteOutletPrizeLogic,
];
