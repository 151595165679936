export default function (/**@type {ApisauceInstance} */ api) {
  const getQuestionTypes = () => api.get(`lookups/questions-types`);
  const getAppTypes = () => api.get(`lookups/app-types`);

  return {
    lookups: {
      getQuestionTypes,
      getAppTypes,
    },
  };
}
