import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";
import { Trans } from "@lingui/react";

const apiNamespace = "products";

const getAllProductsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_PRODUCTS,
});
const getProductWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PRODUCT_WITH_ID,
});

const addProductLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_PRODUCT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/products/list"));
  }
});

const SaveProductCityOffersLogic = logic(apiNamespace, {
  actionName: ActionTypes.SAVE_PRODUCT_CITY_OFFERS,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  // successCb: (dispatch) => {
  //   dispatch(push("/products/list"));
  // }
});
const deleteProductCityOffersLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_PRODUCT_CITY_OFFERS,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
  // successCb: (dispatch) => {
  //   dispatch(push("/products/list"));
  // }
});
const editProductLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_PRODUCT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/products/list"));
  }
});

const deleteProductLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_PRODUCT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllProductsLogic,
  getProductWithIdLogic,
  addProductLogic,
  editProductLogic,
  deleteProductLogic,
  deleteProductCityOffersLogic,
  SaveProductCityOffersLogic
];
