// import { createLogic } from "redux-logic";
import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";


const apiNamespace = "Supervisors";

const getAllSupervisorsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_SUPERVISORS,
  showErrorMessage: true,
  showSuccessMessage: false,
});
const getSupervisorLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SUPERVISOR,
  showErrorMessage: true,
  showSuccessMessage: false,
});

const addSupervisorLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_SUPERVISOR,
  showErrorMessage: true,
  successMessage: "Supervisor created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/supervisors/list"));
  },
});

const deleteSupervisorLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_SUPERVISOR,
  showErrorMessage: true,
  successMessage: "Supervisor Deleted successfully",
  showSuccessMessage: true,
});
const editSupervisorLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_SUPERVISOR,
  showErrorMessage: true,
  showSuccessMessage: "Supervisor Edited Successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/supervisors/list"));
  },
});


export default [
  getAllSupervisorsLogic,
  addSupervisorLogic,
  deleteSupervisorLogic,
  editSupervisorLogic,
  getSupervisorLogic
];
