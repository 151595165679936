import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllSupervisors: { args: ["payload"], meta: { async: true } },
    getSupervisor: { args: ["payload"], meta: { async: true } },
    setSupervisor: { args: ["payload"], meta: { async: true } },
    addSupervisor: { args: ["payload"], meta: { async: true } },
    deleteSupervisor: { args: ["payload"], meta: { async: true } },
    editSupervisor: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } }
  },
  {
    prefix: "@app/supervisors/"
  }
);
export const ActionTypes = Types;
export default Creators;