import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllOffers: { args: ["payload"], meta: { async: true } },
    getOfferWithId: { args: ["payload"], meta: { async: true } },
    addOffer: { args: ["payload"], meta: { async: true } },
    editOffer: { args: ["payload"], meta: { async: true } },
    deleteOffer: { args: ["payload"], meta: { async: true } },

    setOffer: { args: ["payload"], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/offers/",
  }
);
export const ActionTypes = Types;
export default Creators;
