import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  shiftSessions: [],
  exportedSessions: [],
  shiftSession: {},
  monthlyReports:[]
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllShiftSessions",
    stateName: "shiftSessions",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getExportedSessions",
    stateName: "exportedSessions",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getMonthlyReports",
    stateName: "monthlyReports",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getShiftSessionWithId",
    stateName: "shiftSession",
    async: true,
    isPaginated: false,
  }),
});
