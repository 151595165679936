import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    //Reports
    getAllReportResponses: { args: ["payload"], meta: { async: true } },
    getReportResponseWithId: { args: ["payload"], meta: { async: true } },
    //Questions
    getAllReportQuestions: { args: ["payload"], meta: { async: true } },
    addReportQuestion: { args: ["payload"], meta: { async: true } },
    editReportQuestion: { args: ["payload"], meta: { async: true } },
    deleteReportQuestion: { args: ["payload"], meta: { async: true } },

    storeSelectedItemIndex: { args: ["payload"], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/reports/",
  }
);
export const ActionTypes = Types;
export default Creators;
