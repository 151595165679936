import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";
import { Trans } from "@lingui/react";
const apiNamespace = "offers";

const getAllOffersLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_OFFERS,
});
const getOfferWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_OFFER_WITH_ID,
});

const addOfferLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_OFFER,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/offers/list"));
  },
});

const editOfferLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_OFFER,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/offers/list"));
  },
});

const deleteOfferLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_OFFER,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllOffersLogic,
  getOfferWithIdLogic,
  addOfferLogic,
  editOfferLogic,
  deleteOfferLogic,
];
