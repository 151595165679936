export default function (/**@type {ApisauceInstance} */ api) {
  const getGeneralInfo = () => api.get(`dashboard/general-info`);
  const getClientResponse = (filters) => api.get(`dashboard/daily-client-surveys`, filters);
  const getProducts = (filters) => api.get(`dashboard/most-selected-products`, filters);
  const getOffers = (filters) => api.get(`dashboard/most-selected-offers`, filters);

  const getClientResponces = (paging) =>
    api.get(`Survey/client-response`, paging);
  const getClientResponceWithId = (id) =>
    api.get(`Survey/client-response/${id}`);

  const getResponsesSummary = (filters) =>
    api.get(`dashboard/responses-summary`, filters);

  return {
    dashboard: {
      getGeneralInfo,
      getClientResponse,
      getProducts,
      getOffers,
      getClientResponces,
      getClientResponceWithId,
      getResponsesSummary,
    },
  };
}
