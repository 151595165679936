import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getQuestionTypes:{ args: [], meta: { async: true } },
    getAppTypes:{ args: [], meta: { async: true } },
  },
  {
    prefix: "@app/Lookups/",
  }
);
export const ActionTypes = Types;
export default Creators;
