import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";
import logic from "../../utils/genLogic";
import { push } from "connected-react-router";

const apiNamespace = "spinWheel";

const getAllSpinLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_SPIN,
});

const getSpinWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SPIN_WITH_ID,
});

const createSpinLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_SPIN,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/SpinningWheel/list"));
  },
});

const updateSpinLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_SPIN,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/SpinningWheel/list"));
  },
});
const deleteSpinLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_SPIN,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

// Rewards
const getAllRewardsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_REWARDS,
});

const getRewardWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_REWARD_WITH_ID,
});
const createRewardLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_REWARD,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  // successCb: (dispatch) => {
  //   dispatch(push("/rewards/list"));
  // },
});

const updateRewardLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_REWARD,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/rewards/list"));
  },
});

const deleteRewardLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_REWARD,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

// Supervisor
const assignedSupervisorLogic = logic(apiNamespace, {
  actionName: ActionTypes.ASSIGNED_SUPERVISOR,
});

const notAssignedSupervisorLogic = logic(apiNamespace, {
  actionName: ActionTypes.NOT_ASSIGNED_SUPERVISOR,
});

const assignSupervisorLogic = logic(apiNamespace, {
  actionName: ActionTypes.ASSIGN_SUPERVISOR,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

const removeSupervisorFromSpinLogic = logic(apiNamespace, {
  actionName: ActionTypes.REMOVE_SUPERVISOR_FROM_SPIN,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllSpinLogic,
  getSpinWithIdLogic,
  createSpinLogic,
  updateSpinLogic,
  deleteSpinLogic,

  // Supervisor
  assignedSupervisorLogic,
  notAssignedSupervisorLogic,
  assignSupervisorLogic,
  removeSupervisorFromSpinLogic,

  // Rewards
  getAllRewardsLogic,
  getRewardWithIdLogic,
  createRewardLogic,
  updateRewardLogic,
  deleteRewardLogic,
];
