export default function (/**@type {ApisauceInstance} */ api) {
    const getRefusalReasons = (paging) => api.get(`RefusalReason`, paging);
    const getRefusalReasonWithId = (id) => api.get(`RefusalReason/refusal-reason/${id}`);
    const addRefusalReason = (payload) => api.post(`RefusalReason/add-refusal-reason`, payload);
    const editRefusalReason = ({ id, payload }) => api.put(`RefusalReason/edit-refusal-reason/${id}`, payload);
    const deleteRefusalReason = (id) => api.delete(`RefusalReason/delete-refusal-reason/${id}`);

    return {
        refusalReasons: {
            getRefusalReasons,
            getRefusalReasonWithId,
            addRefusalReason,
            editRefusalReason,
            deleteRefusalReason
        },
    };
}
