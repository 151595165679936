import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";

const apiNamespace = "shiftSessions";

const getAllShiftSessionsLogic = logic(apiNamespace, {
    actionName: ActionTypes.GET_ALL_SHIFT_SESSIONS,
});

const getExportedSessionsLogic = logic(apiNamespace, {
    actionName: ActionTypes.GET_EXPORTED_SESSIONS,
});

const getShiftSessionWithIdLogic = logic(apiNamespace, {
    actionName: ActionTypes.GET_SHIFT_SESSION_WITH_ID,
});
const getMonthlyReportsLogic = logic(apiNamespace, {
    actionName: ActionTypes.GET_MONTHLY_REPORTS,
});

export default [
    getAllShiftSessionsLogic,
    getShiftSessionWithIdLogic,
    getMonthlyReportsLogic,
    getExportedSessionsLogic

];
