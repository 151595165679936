import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllSpin: { args: ["payload"], meta: { async: true } },
    getSpinWithId: { args: ["payload"], meta: { async: true } },
    createSpin: { args: ["payload"], meta: { async: true } },
    updateSpin: { args: ["payload"], meta: { async: true } },
    deleteSpin: { args: ["payload"], meta: { async: true } },

    // Supervisor
    removeSupervisorFromSpin: { args: ["payload"], meta: { async: true } },
    assignSupervisor: { args: ["payload"], meta: { async: true } },
    assignedSupervisor: { args: ["payload"], meta: { async: true } },
    notAssignedSupervisor: { args: ["payload"], meta: { async: true } },

    // Rewards
    getAllRewards: { args: ["payload"], meta: { async: true } },
    getRewardWithId: { args: ["payload"], meta: { async: true } },
    createReward: { args: ["payload"], meta: { async: true } },
    updateReward: { args: ["payload"], meta: { async: true } },
    deleteReward: { args: ["payload"], meta: { async: true } },
    setReward: { args: ["payload"], meta: { async: false } },

    setData: { args: ["payload"], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/spinWheel/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
