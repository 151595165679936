import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState(
    "lookups",
    { metadata: undefined },
    {
      questionTypes: [],
      appTypes: [],
    }
  ),
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getQuestionTypes",
    stateName: "questionTypes",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAppTypes",
    stateName: "appTypes",
    async: true,
    isPaginated: false,
  }),
});
