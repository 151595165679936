export default function (/**@type {ApisauceInstance} */ api) {

  
    const getAllNationalities = (paging) => api.get(`Nationality`,paging);

    const addNationality = (payload) =>  api.post(`Nationality`, payload);
   
    const editNationality = ({ payload, id }) =>api.put(`Nationality/${id}`, payload);
  
    const deleteNationality = (id) => api.delete(`Nationality/${id}`);

    return {
      Nationalities: {
        getAllNationalities,
        addNationality,
        editNationality,
        deleteNationality
      },
    };
  }
  