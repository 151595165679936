import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";
const apiNamespace = "reports";

const getAllReportResponsesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_REPORT_RESPONSES,
});
const getReportResponseWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_REPORT_RESPONSE_WITH_ID,
});

// Questions 
const getAllReportQuestionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_REPORT_QUESTIONS,
});

const addReportQuestionLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_REPORT_QUESTION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  
});

const editReportQuestionLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_REPORT_QUESTION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deleteReportQuestionLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_REPORT_QUESTION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  // Reports
  getAllReportResponsesLogic,
  getReportResponseWithIdLogic,

  // Questions
  getAllReportQuestionsLogic,
  addReportQuestionLogic,
  editReportQuestionLogic,
  deleteReportQuestionLogic,
];
