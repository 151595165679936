import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "lookups";

const getQuestionTypesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_QUESTION_TYPES,
});
const getAppTypesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_APP_TYPES,
});



export default [
  getQuestionTypesLogic,
  getAppTypesLogic
];
