import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    saveSurveyInformation: { args: ["payload"], meta: { async: false } },
    getSurveyWithId: { args: ["payload"], meta: { async: true } },
    getSurveys: { args: ["payload"], meta: { async: true } },
    getSurveyResults: { args: ["payload"], meta: { async: true } },
    getUserSurveys: { args: ["payload"], meta: { async: true } },
    getUserSurveysWithId: { args: ["payload"], meta: { async: true } },
    createSurvey: { args: ["payload"], meta: { async: true } },
    addSurveyResponse: { args: ["payload"], meta: { async: true } },
    editSurvey: { args: ["payload"], meta: { async: true } },
    deleteSurvey: { args: ["payload"], meta: { async: true } },
    addSurveyQuestion: { args: ["payload"], meta: { async: true } },
    removeSurveyQuestion: { args: ["payload"], meta: { async: true } },
    editSurveyQuestion: { args: ["payload"], meta: { async: true } },
    setSurvey: { args: ["payload"], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/surveys/",
  }
);
export const ActionTypes = Types;
export default Creators;
