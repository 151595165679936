export default function (/**@type {ApisauceInstance} */ api) {
  //Reports
  const getAllReportResponses = (paging) => api.get(`ReportResponse`, paging);
  const getReportResponseWithId = (id) => api.get(`ReportResponse/${id}`);

  //Questions
  const getAllReportQuestions = (paging) => api.get(`ReportQuestion`, paging);
  const addReportQuestion = (payload) => api.post(`ReportQuestion`, payload);
  const editReportQuestion = ({ payload, id }) =>
    api.put(`ReportQuestion/${id}`, payload);
  const deleteReportQuestion = (id) => api.delete(`ReportQuestion/${id}`);

  return {
    reports: {
      //Reports
      getAllReportResponses,
      getReportResponseWithId,
      //Questions
      getAllReportQuestions,
      addReportQuestion,
      editReportQuestion,
      deleteReportQuestion,
    },
  };
}
