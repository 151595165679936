import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
    {
        getRefusalReasons: { args: ["payload"], meta: { async: true } },
        getRefusalReasonWithId: { args: ["payload"], meta: { async: true } },
        addRefusalReason: { args: ["payload"], meta: { async: true } },
        editRefusalReason: { args: ["payload"], meta: { async: true } },
        deleteRefusalReason: { args: ["payload"], meta: { async: true } },
        showDialog: { args: [], meta: { async: false } },
        showDeleteDialog: { args: [], meta: { async: false } },
        resetDialog: { args: [], meta: { async: false } },
    },
    {
        prefix: "@app/RefusalReasons/",
    }
);
export const ActionTypes = Types;
export default Creators;
