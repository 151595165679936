import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllProducts: { args: ["payload"], meta: { async: true } },
    getProductWithId: { args: ["payload"], meta: { async: true } },
    addProduct: { args: ["payload"], meta: { async: true } },
    editProduct: { args: ["payload"], meta: { async: true } },
    saveProductCityOffers: { args: ["payload"], meta: { async: true } },
    deleteProductCityOffers: { args: ["payload"], meta: { async: true } },
    deleteProduct: { args: ["payload"], meta: { async: true } },

    setProduct: { args: ["payload"], meta: { async: false } },

    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/products/",
  }
);
export const ActionTypes = Types;
export default Creators;
