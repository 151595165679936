export default function (/**@type {ApisauceInstance} */ api) {
    const getAllAgeRanges = (paging) => api.get(`AgeRange`,paging);
    const getAgeRangeWithId= (id) => api.get(`AgeRange/age-range/${id}`);
    const addAgeRange = (payload) => api.post(`AgeRange/add-age-range`, payload);
    const editAgeRange = ({ id, payload }) => api.put(`AgeRange/edit-age-range/${id}`, payload);
    const deleteAgeRange = (id) => api.delete(`AgeRange/delete-age-range/${id}`);
  
    return {
      ageRanges: {
        getAllAgeRanges,
       getAgeRangeWithId,
       addAgeRange,
       editAgeRange,
       deleteAgeRange
      },
    };
  }
  