import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
};


const ageRangeEditedSuccess = (state, draft, payload) => {
  const ageRanges = state.ageRanges|| [];
  var ageRangeIndex = ageRanges.findIndex((u) => u.id === payload.id);
  console.log("index",ageRangeIndex)
  draft.ageRanges[ageRangeIndex] = payload;
  draft.openDialog = false;

};

const ageRangeCreatedSuccess = (state, draft, payload) => {
  // debugger
  const  ageRanges = state.ageRanges || [];
 
  draft.ageRanges= [...ageRanges, payload];
  draft.openDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

const ageRangeDeleteSuccess = (state, draft, payload) => {
  const  ageRanges = state.ageRanges || [];
  draft.ageRanges = ageRanges.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};




export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllAgeRanges",
    stateName: "ageRanges",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "addAgeRange",
    stateName: "ageRange",
    async: true,
    isPaginated: false,
    successCb:ageRangeCreatedSuccess
    
  }),
  ...bindReducers(ActionTypes, {
    action: "editAgeRange",
    stateName: "ageRange",
    async: true,
    isPaginated: false,
    successCb:ageRangeEditedSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteAgeRange",
    stateName: "ageRange",
    async: true,
    isPaginated: false,
    successCb:ageRangeDeleteSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),

});
