export default function (/**@type {ApisauceInstance} */ api) {

  
    const getAllCompanyInfos = () => api.get(`CompanyInfo/companyInfos`);

    const getCompanyInfoWithId = (id) => api.get(`CompanyInfo/companyInfo/${id}`);

    const addCompanyInfo = (payload) =>api.post(`CompanyInfo/add-company-info`, payload);
  
    const editCompanyInfo = ({payload, id }) =>api.put(`CompanyInfo/edit-company-info/${id}`, payload);
  
    const deleteCompanyInfo = (id) => api.delete(`CompanyInfo/delete-company-info/${id}`);

    return {
      companyInfos: {
        getAllCompanyInfos,
        addCompanyInfo,
        editCompanyInfo,
        deleteCompanyInfo,
        getCompanyInfoWithId
      },
    };
  }
  