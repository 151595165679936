import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "companyInfos";


const getAllCompanyInfosLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_COMPANY_INFOS,
});

const getCompanyInfoWithId = logic(apiNamespace, {
  actionName: ActionTypes.GET_COMPANY_INFO_WITH_ID,
});
const addCompanyInfoLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_COMPANY_INFO,
  successCb: (dispatch) => {
    dispatch(push("/companyInfo"));
  }
});

const editCompanyInfoLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_COMPANY_INFO,
  successCb: (dispatch) => {
    dispatch(push("/companyInfo"));
  }
});

const deleteCompanyInfoLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_COMPANY_INFO,
});



export default [
  getAllCompanyInfosLogic,
  addCompanyInfoLogic,
  editCompanyInfoLogic,
  deleteCompanyInfoLogic,
  getCompanyInfoWithId

];
