import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    // categories
    getAllBrands: { args: ["payload"], meta: { async: true } },
    getBrandWithId: { args: ["payload"], meta: { async: true } },
    addBrand: { args: ["payload"], meta: { async: true } },
    editBrand: { args: ["payload"], meta: { async: true } },
    deleteBrand: { args: ["payload"], meta: { async: true } },
    // products
    addProduct: { args: ["payload"], meta: { async: true } },
    editProduct: { args: ["payload"], meta: { async: true } },
    deleteProduct: { args: ["payload"], meta: { async: true } },

    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/brands/",
  }
);
export const ActionTypes = Types;
export default Creators;
