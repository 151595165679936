export default function (/**@type {ApisauceInstance} */ api) {
  const getAllSpin = (paging) => api.get("/Spin/search", paging);
  const getSpinWithId = (id) => api.get("/Spin/find/" + id);
  const createSpin = (payload) => api.post("/Spin/create", payload);
  const updateSpin = (payload) => api.put("/Spin/edit", payload);
  const deleteSpin = (id) => api.delete("/Spin/remove/" + id);

  // assign spin to supervisor
  const assignSupervisor = (payload) => api.post("/Spin/assign-supervisor-to-spin", payload);
  const removeSupervisorFromSpin = ({spinEventId,supervisorId}) => 
  api.delete(`/Spin/remove-supervisor-from-spin/${spinEventId}/${supervisorId}`);
  const assignedSupervisor = (paging) => api.get("/Spin/assigned-supervisors-to-event", paging);
  const notAssignedSupervisor = (paging) => api.get("/Spin/not-assigned-supervisors-to-event", paging);

  // Rewards
  const getAllRewards = (paging) => api.get("/Spin/spin-rewards", paging);
  const getRewardWithId = (id) => api.get("/Spin/spin-reward/" + id);
  const createReward = (payload) => api.post("/Spin/create-spin-reward", payload);
  const updateReward = (payload) => api.put("/Spin/edit-spin-reward", payload);
  const deleteReward = (id) => api.delete("/Spin/remove-spin-reward/" + id);

  return {
    spinWheel: {
      getAllSpin,
      getSpinWithId,
      createSpin,
      updateSpin,
      deleteSpin,

      assignSupervisor,
      assignedSupervisor,
      notAssignedSupervisor,
      removeSupervisorFromSpin,

      getAllRewards,
      getRewardWithId,
      createReward,
      updateReward,
      deleteReward,
    },
  };
}
