import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("offers", { metadata: undefined }, []),
};

const offerDeleteSuccess = (state, draft, payload) => {
  debugger;
  const offers = state.offers || [];
  draft.offers = offers.filter((a) => a.id != payload.data.id);
  draft.openDeleteDialog = false;
};

const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllOffers",
    stateName: "offers",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getOfferWithId",
    stateName: "offer",
    async: true,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "addOffer",
    stateName: "offer",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "editOffer",
    stateName: "offer",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteOffer",
    stateName: "offer",
    async: true,
    isPaginated: false,
    successCb: offerDeleteSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "setOffer",
    stateName: "offer",
    async: false,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
