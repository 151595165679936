import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllGroups: { args: ["payload"], meta: { async: true } },
    getGroupWithId: { args: ["payload"], meta: { async: true } },
    createGroup: { args: ["payload"], meta: { async: true } },
    updateGroup: { args: ["payload"], meta: { async: true } },
    deleteGroup: { args: ["payload"], meta: { async: true } },

    /// group clients
    getGroupClients: { args: ["payload"], meta: { async: true } },
    createGroupClient: { args: ["payload"], meta: { async: true } },
    createGroupClients: { args: ["payload"], meta: { async: true } },

    /// group outlets
    getNonGroupedOutlets: { args: ["payload"], meta: { async: true } },
    createGroupOutlet: { args: ["payload"], meta: { async: true } },
    createGroupOutlets: { args: ["payload"], meta: { async: true } },
    removeGroupOutlet: { args: ["payload"], meta: { async: true } },
    getGroupOutletsList: { args: ["payload"], meta: { async: true } },
    uploadGroupOutlets: { args: ["payload"], meta: { async: true } },
    removeGroupOutlets: { args: ["payload"], meta: { async: true } },


    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
    setGroup: { args: ["payload"], meta: { async: false } },
  },
  {
    prefix: "@app/group/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
