import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllCompanyInfos: { args: [], meta: { async: true } },
    getCompanyInfoWithId: { args: ["payload"], meta: { async: true } },
    addCompanyInfo: { args: ["payload"], meta: { async: true } },
    editCompanyInfo: { args: ["payload"], meta: { async: true } },
    deleteCompanyInfo: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/CompanyInfos/",
  }
);
export const ActionTypes = Types;
export default Creators;
