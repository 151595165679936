export default function (/**@type {ApisauceInstance} */ api) {


  const getAllCountries = () => api.get(`Country`);
  const createCountry = (payload) => api.post(`Country/add-country`, payload);
  const editCountry = ({ id, payload }) => api.put(`Country/edit-country/${id}`, payload);
  const deleteCountry = (id) => api.delete(`Country/delete-country/${id}`);
  const getAllCities = (paging) => api.get(`City`);
  const getCities = (paging) => api.get(`City`, paging);
  const createCity = (payload) => api.post(`City`, payload);
  const editCity = ({ id, payload }) => api.put(`City/${id}`, payload);
  const deleteCity = (id) => api.delete(`City/${id}`);

  return {
    Cities: {
      getAllCities,
      getCities,
      createCity,
      editCity,
      deleteCity,
      getAllCountries,
      createCountry,
      editCountry,
      deleteCountry,
    },
  };
}

