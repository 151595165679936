export default function (/**@type {ApisauceInstance} */ api) {
  const getAllOutlets = (paging) => api.get(`Outlet`, paging);
  const getOutletWithId = (id) => api.get(`Outlet/${id}`);
  const addOutlet = (payload) => api.post(`Outlet`, payload);
  const uploadOutlets = (payload) => api.post(`Outlet/upload-outlets`, payload);
  const editOutlet = ({ payload, id }) => api.put(`Outlet/${id}`, payload);
  const deleteOutlet = (id) => api.delete(`Outlet/${id}`);

  return {
    outlets: {
      getAllOutlets,
      getOutletWithId,
      addOutlet,
      uploadOutlets,
      editOutlet,
      deleteOutlet,
    },
  };
}
