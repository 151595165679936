import logic from "src/utils/genLogic";
import actions, { ActionTypes } from "./actions";
import { push } from "connected-react-router";
import { Trans } from "@lingui/react";
const apiNamespace = "Cities";

const getCitiesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CITIES,
});

const getAllCitiesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_CITIES,
});

const getAllCountriesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_COUNTRIES,
});
const createCountryLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_COUNTRY,

});
const editCountryLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_COUNTRY,
});
const deleteCountryLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_COUNTRY,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,

});
const createCityLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_CITY,
  successCb: (dispatch) => {
    dispatch(push("/cities/list"));
  },
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});
const editCityLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_CITY,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});
const deleteCityLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_CITY,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getCitiesLogic,
  createCityLogic,
  editCityLogic,
  deleteCityLogic,
  getAllCountriesLogic,
  createCountryLogic,
  editCountryLogic,
  deleteCountryLogic,
  getAllCitiesLogic
];
