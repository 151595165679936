import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("reportResponses", { metadata: undefined }, []),
  ...defaultState("reportResponse", { metadata: undefined }, {}),
  ...defaultState("reportQuestions", { metadata: undefined }, []),
};

const reportQuestionAdded = (state, draft, payload) => {
  const reportQuestions = state.reportQuestions || [];
  draft.reportQuestions = [...reportQuestions, payload];
  draft.openDialog = false;
};

const reportQuestionEdited = (state, draft, payload) => {
  debugger
  const index = state.selectedItem;
  draft.reportQuestions[index] = payload;
  draft.openDialog = false;
};

const reportQuestionDeleted = (state, draft, payload) => {
  const index = state.selectedItem;
  draft.reportQuestions.splice(index, 1);
  draft.openDeleteDialog = false;
};

//
const storeSelectedItemIndex = (state, draft, payload) => {
  draft.selectedItem = payload;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

export const reducer = createReducer(initialState, {
  //reports

  ...bindReducers(ActionTypes, {
    action: "getAllReportResponses",
    stateName: "reportResponses",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getReportResponseWithId",
    stateName: "reportResponse",
    async: true,
    isPaginated: false,
  }),

  //questions

  ...bindReducers(ActionTypes, {
    action: "getAllReportQuestions",
    stateName: "reportQuestions",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "addReportQuestion",
    stateName: "reportQuestion",
    async: true,
    isPaginated: false,
    successCb: reportQuestionAdded,
  }),
  ...bindReducers(ActionTypes, {
    action: "editReportQuestion",
    stateName: "reportQuestion",
    async: true,
    isPaginated: false,
    successCb: reportQuestionEdited,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteReportQuestion",
    stateName: "reportQuestion",
    async: true,
    isPaginated: false,
    successCb: reportQuestionDeleted,
  }),

  //
  ...bindReducers(ActionTypes, {
    action: "storeSelectedItemIndex",
    stateName: "selectedItemIndex",
    async: false,
    isPaginated: false,
    successCb: storeSelectedItemIndex,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
