import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
    ...defaultState("refusalReasons", { metadata: undefined }, []),
    ...defaultState(
        "refusalReason",
        { metadata: undefined },

    ),
};


const refusalReasonEditedSuccess = (state, draft, payload) => {
    const refusalReasons = state.refusalReasons || [];
    var refusalReasonIndex = refusalReasons.findIndex((u) => u.id === payload.id);
    draft.refusalReasons[refusalReasonIndex] = payload;
    draft.openDialog = false;

};

const refusalReasonCreatedSuccess = (state, draft, payload) => {
    // debugger
    const refusalReasons = state.refusalReasons || [];

    draft.refusalReasons = [...refusalReasons, payload];
    draft.openDialog = false;
};
const showDialog = (state, draft, payload) => {
    draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
    draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
    draft.openDialog = false;
    draft.openDeleteDialog = false;
    draft.brand_errors = [];
};

const refusalReasonDeleteSuccess = (state, draft, payload) => {
    const refusalReasons = state.refusalReasons || [];
    draft.refusalReasons = refusalReasons.filter((a) => a.id != payload.id);
    draft.openDeleteDialog = false;
};




export const reducer = createReducer(initialState, {
    ...bindReducers(ActionTypes, {
        action: "getRefusalReasons",
        stateName: "refusalReasons",
        async: true,
        isPaginated: true,
        // successCb: console.log("hhhhhdone")
    }),
    ...bindReducers(ActionTypes, {
        action: "addRefusalReason",
        stateName: "refusalReason",
        async: true,
        isPaginated: false,
        successCb: refusalReasonCreatedSuccess

    }),
    ...bindReducers(ActionTypes, {
        action: "editRefusalReason",
        stateName: "refusalReason",
        async: true,
        isPaginated: false,
        successCb: refusalReasonEditedSuccess
    }),
    ...bindReducers(ActionTypes, {
        action: "deleteRefusalReason",
        stateName: "refusalReason",
        async: true,
        isPaginated: false,
        successCb: refusalReasonDeleteSuccess
    }),
    ...bindReducers(ActionTypes, {
        action: "showDeleteDialog",
        stateName: "showDeleteDialog",
        async: false,
        isPaginated: false,
        successCb: showDeleteDialog
    }),

    ...bindReducers(ActionTypes, {
        action: "showDialog",
        stateName: "showDialog",
        async: false,
        isPaginated: false,
        successCb: showDialog,
    }),
    ...bindReducers(ActionTypes, {
        action: "resetDialog",
        stateName: "resetDialog",
        async: false,
        isPaginated: false,
        successCb: resetDialog,
    }),

});
