import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
    {
        getAllShiftSessions: { args: ["payload"], meta: { async: true } },
        getMonthlyReports: { args: ["payload"], meta: { async: true } },
        getExportedSessions: { args: ["payload"], meta: { async: true } },
        getShiftSessionWithId: { args: ["payload"], meta: { async: true } },

    },
    {
        prefix: "@app/ShiftSessions/",
    }
);
export const ActionTypes = Types;
export default Creators;