
  
import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("outletTypes", { metadata: undefined }, []),
  ...defaultState("outletType", { metadata: undefined }, {}),
};
const outletTypeEditedSuccess = (state, draft, payload) => {
  const outletTypes = state.outletTypes || [];
  var outletTypeIndex = outletTypes.findIndex((u) => u.id === payload.id);
  draft.outletTypes[outletTypeIndex] = payload;
  draft.openDialog = false;
};

const outletTypeCreatedSuccess = (state, draft, payload) => {
  const outletTypes = state.outletTypes || [];
  draft.outletTypes = [...outletTypes, payload];
  draft.openDialog = false;
};

const outletTypeDeleteSuccess = (state, draft, payload) => {
  const outletTypes = state.outletTypes || [];
  draft.outletTypes = outletTypes.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.outletType_errors = [];
};

export const reducer = createReducer(initialState, {         
  ...bindReducers(ActionTypes, {
     action: "getAllOutletTypes",
     stateName: "outletTypes",
     async: true,
     isPaginated: true
  }),
  ...bindReducers(ActionTypes, {
     action: "getOutletTypeWithId",
     stateName: "outletType",
     async: true,
     isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "createOutletType",
    stateName: "outletType",
    async: true,
    isPaginated: false.valueOf,
    successCb:outletTypeCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
     action: "updateOutletType",
     stateName: "outletType",
     async: true,
     isPaginated: false,
     successCb:outletTypeEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteOutletType",
    stateName: "outletType",
    async: true,
    isPaginated: false,
    successCb:outletTypeDeleteSuccess,
  }),
  
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
  