export default function (/**@type {ApisauceInstance} */ api) {
  const getAllOffers = (paging) => api.get(`offer`, paging);
  const getOfferWithId = (id) => api.get(`offer/${id}`);
  const addOffer = (payload) => api.post(`offer`, payload);
  const editOffer = ({ payload, id }) => api.put(`offer/${id}`, payload);
  const deleteOffer = (id) => api.delete(`offer/${id}`);

  return {
    offers: {
      getAllOffers,
      getOfferWithId,
      addOffer,
      editOffer,
      deleteOffer,
    },
  };
}
