import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("prizes", { metadata: undefined }, []),
  ...defaultState(
    "prize",
    { metadata: undefined },
    {
      products: [],
    }
  ),
  ...defaultState("outletPrizes", { metadata: undefined }, []),
  ...defaultState("outletPrize", { metadata: undefined }, {}),
};

const prizeEditedSuccess = (state, draft, payload) => {
  const prizes = state.prizes || [];
  var prizeIndex = prizes.findIndex((u) => u.id === payload.id);
  draft.prizes[prizeIndex] = payload;
  draft.openDialog = false;
};

const prizeCreatedSuccess = (state, draft, payload) => {
  // debugger
  const prizes = state.prizes || [];
  draft.prizes = [...prizes, payload];
  draft.openDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

const prizeDeleteSuccess = (state, draft, payload) => {
  const prizes = state.prizes || [];
  draft.prizes = prizes.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};

const outletPrizeEditedSuccess = (state, draft, payload) => {
  const outletPrizes = state.outletPrizes || [];
  var prizeIndex = outletPrizes.findIndex((u) => u.id === payload.id);
  draft.outletPrizes[prizeIndex] = payload;
  draft.openDialog = false;
};

const outletPrizeCreatedSuccess = (state, draft, payload) => {
  // debugger
  const outletPrizes = state.outletPrizes || [];
  draft.outletPrizes = [...outletPrizes, payload];
  draft.openDialog = false;
};
const outletPrizeDeleteSuccess = (state, draft, payload) => {
  const outletPrizes = state.outletPrizes || [];
  draft.outletPrizes = outletPrizes.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};
export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllPrizes",
    stateName: "prizes",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "addPrize",
    stateName: "prize",
    async: true,
    isPaginated: false,
    successCb: prizeCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editPrize",
    stateName: "prize",
    async: true,
    isPaginated: false,
    successCb: prizeEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deletePrize",
    stateName: "prize",
    async: true,
    isPaginated: false,
    successCb: prizeDeleteSuccess,
  }),
  /////////////////////
  ...bindReducers(ActionTypes, {
    action: "getAllOutletPrizes",
    stateName: "outletPrizes",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "addOutletPrize",
    stateName: "outletPrize",
    async: true,
    isPaginated: false,
    successCb: outletPrizeCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editOutletPrize",
    stateName: "outletPrize",
    async: true,
    isPaginated: false,
    successCb: outletPrizeEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteOutletPrize",
    stateName: "outletPrize",
    async: true,
    isPaginated: false,
    successCb: outletPrizeDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
