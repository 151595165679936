export default function (/**@type {ApisauceInstance} */ api) {
  
    const getAllChannels = (paging) => api.get(`channel`, paging);
    const getChannelWithId = (id) => api.get(`channel/${id}`);
    const createChannel = (payload) => api.post(`channel`, payload);
  
    const updateChannel = ({ payload, id }) => api.put(`channel/${id}`, payload);
  
    const deleteChannel = (id) => api.delete(`channel/${id}`);
  
   
  
    return {
        channel: {
            getAllChannels,
            getChannelWithId,
            createChannel,
            updateChannel,
            deleteChannel,
      },
    };
  }
  