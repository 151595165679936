export default function (/**@type {ApisauceInstance} */ api) {
  // brands

  const getAllBrands = (paging) => api.get(`brand`, paging);
  const getBrandsWithId = (id) => api.get(`brand/${id}`);
  const addBrand = (payload) => api.post(`brand`, payload);

  const editBrand = ({ payload, id }) => api.put(`brand/${id}`, payload);

  const deleteBrand = (id) => api.delete(`brand/${id}`);

  // products
  const addProduct = ({ payload }) => api.post(`product`, payload);

  const editProduct = ({ payload, id }) => api.put(`product/${id}`, payload);

  const deleteProduct = (id) => api.delete(`product/${id}`);

  return {
    brands: {
      // brands
      getAllBrands,
      getBrandsWithId,
      addBrand,
      editBrand,
      deleteBrand,
      // products
      addProduct,
      editProduct,
      deleteProduct,
    },
  };
}
