import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("companyInfos", { metadata: undefined }, []),
  ...defaultState(
    "companyInfo",
    { metadata: undefined },
    {
      products: [],
    }
  ),
};


const companyInfoEditedSuccess = (state, draft, payload) => {
  const CompanyInfos = state.CompanyInfos || [];
  var CompanyInfoIndex = CompanyInfos.findIndex((u) => u.id === payload.id);
  draft.CompanyInfos[CompanyInfoIndex] = payload;
};

const companyInfoCreatedSuccess = (state, draft, payload) => {
 
  const CompanyInfos = state.CompanyInfos || [];
  draft.CompanyInfos = [...CompanyInfos, payload];

  draft.openDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

const companyInfoDeleteSuccess = (state, draft, payload) => {
  debugger
  const companyInfos = state.companyInfos || [];
  draft.companyInfos = companyInfos.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};



export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllCompanyInfos",
    stateName: "companyInfos",
    async: true,
    isPaginated: true
  }),
  ...bindReducers(ActionTypes, {
    action: "getCompanyInfoWithId",
    stateName: "companyInfo",
    async: true,
    isPaginated: false,
    
  }),
  ...bindReducers(ActionTypes, {
    action: "addCompanyInfo",
    stateName: "companyInfo",
    async: true,
    isPaginated: false,
    successCb:companyInfoCreatedSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "editCompanyInfo",
    stateName: "companyInfo",
    async: true,
    isPaginated: false,
    successCb:companyInfoEditedSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteCompanyInfo",
    stateName: "companyInfo",
    async: true,
    isPaginated: false,
    successCb:companyInfoDeleteSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
