
let app = localStorage.getItem('app_name');


export default {
  useLocalApi: true,
  appName: "Razy",
  appTitle: "Razy",
  appDescription: "razytech",
  signalR: false,
  baseUrl: "",
  // baseUrl:"https://www.nileappco.com/",
  // apiRoot: "https://www.nileappco.com/api",
  //  devApiRoot: "http://localhost:5000/api",
  devApiRoot: "https://localhost:5260/",
  // devApiRoot: "https://www.nileappco.com/api",
  pageOptions: [5, 10, 50, 100],
  contactPhone: "",
  contactMail: "",
  addressOnMap: "",
  gen_password_length: 10,
  gen_password_characters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijaklmnopqrstuvwxyz1234567890',
  appSelected: app ? true : false,
  app: app,
  REACT_APP_BASE_API_URL: app === 'roi' ?
    process.env.REACT_APP_BASE_API_URL
    : process.env.REACT_APP_BASE_API_URL_NAKHLA,
};
