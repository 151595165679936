
import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllOutletTypes: { args: ["payload"], meta: { async: true } },
    getOutletTypeWithId: { args: ["payload"], meta: { async: true } },
    createOutletType: { args: ["payload"], meta: { async: true } },
    updateOutletType: { args: ["payload"], meta: { async: true } },
    deleteOutletType: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/outletType/"
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions=Creators;

    