
import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllChannels: { args: ["payload"], meta: { async: true } },
    getChannelWithId: { args: ["payload"], meta: { async: true } },
    createChannel: { args: ["payload"], meta: { async: true } },
    updateChannel: { args: ["payload"], meta: { async: true } },
    deleteChannel: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/channel/"
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions=Creators;

    