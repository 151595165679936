import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("brands", { metadata: undefined }, []),
};

//** Brands
const brandEditedSuccess = (state, draft, payload) => {
  const brands = state.brands || [];
  var brandIndex = brands.findIndex((u) => u.id === payload.id);
  draft.brands[brandIndex] = payload;
  draft.openDialog = false;
};

const brandCreatedSuccess = (state, draft, payload) => {
  const brands = state.brands || [];
  draft.brands = [...brands, payload];
  draft.openDialog = false;
};

const brandDeleteSuccess = (state, draft, payload) => {
  const brands = state.brands || [];
  draft.brands = brands.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};

//** products
const productAddedSuccessfully = (state, draft, payload) => {
  draft.brand.products = [payload, ...state.brand.products];
};

const productEditedSuccessfully = (state, draft, payload) => {
  debugger;
  const products = state.brand.products || [];
  var productIndex = products.findIndex((p) => p.id === payload.id);
  draft.brand.products[productIndex] = payload;
};

const productDeletedSuccessfully = (state, draft, payload) => {
  const products = state.brand.products || [];
  var ptoductIndex = products.findIndex((p) => p.id === payload.id);
  draft.brand.products.splice(ptoductIndex, 1);
};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

export const reducer = createReducer(initialState, {
  //**Brands
  ...bindReducers(ActionTypes, {
    action: "getAllBrands",
    stateName: "brands",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getBrandWithId",
    stateName: "brand",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "addBrand",
    stateName: "brand",
    async: true,
    isPaginated: false,
    successCb: brandCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editBrand",
    stateName: "brand",
    async: true,
    isPaginated: false,
    successCb: brandEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteBrand",
    stateName: "brand",
    async: true,
    isPaginated: false,
    successCb: brandDeleteSuccess,
  }),

  //** Products
  ...bindReducers(ActionTypes, {
    action: "addProduct",
    stateName: "brandProduct",
    async: true,
    isPaginated: false,
    successCb: productAddedSuccessfully,
  }),
  ...bindReducers(ActionTypes, {
    action: "editProduct",
    stateName: "brandProduct",
    async: true,
    isPaginated: false,
    successCb: productEditedSuccessfully,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteProduct",
    stateName: "brandProduct",
    async: true,
    isPaginated: false,
    successCb: productDeletedSuccessfully,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
