import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";

const apiNamespace = "ageRanges";

const getAllAgeRangesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_AGE_RANGES,
});

const getAgeRangeWithId = logic(apiNamespace, {
  actionName: ActionTypes.GET_AGE_RANGE_WITH_ID,
});
const addAgeRangeLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_AGE_RANGE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

const editAgeRangeLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_AGE_RANGE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deleteAgeRangeLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_AGE_RANGE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllAgeRangesLogic,
  addAgeRangeLogic,
  editAgeRangeLogic,
  deleteAgeRangeLogic,
  getAgeRangeWithId,
];
