import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllAgeRanges: { args: ["payload"], meta: { async: true } },
    getAgeRangeWithId: { args: ["payload"], meta: { async: true } },
    addAgeRange: { args: ["payload"], meta: { async: true } },
    editAgeRange: { args: ["payload"], meta: { async: true } },
    deleteAgeRange: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/ageRanges/",
  }
);
export const ActionTypes = Types;
export default Creators;
