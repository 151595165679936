export default function (/**@type {ApisauceInstance} */ api) {

  
    const getAllSupervisors = (paging) => api.get(`Supervisor/supervisors`,paging);

    const addSupervisor = (payload) => api.post(`Supervisor/supervisor`, payload);

    const getSupervisor = (id) => api.get(`Supervisor/${id}`);
    const editSupervisor = ({ payload, id }) =>api.put(`Supervisor/edit-supervisor/${id}`, payload);
  
    const deleteSupervisor = (id) => api.delete(`Supervisor/delete-supervisor/${id}`);

    return {
      Supervisors: {
        getAllSupervisors,
        addSupervisor,
        editSupervisor,
        deleteSupervisor,
        getSupervisor
      },
    };
  }
  