export default function (/**@type {ApisauceInstance} */ api) {
  const getSurveys = (paging) => api.get("survey", paging);
  const getUserSurveys = () => api.get("survey/user-surveys");
  const getSurveyResults = (paging) => api.get("survey/survey-answers",paging);
  const getUserSurveysWithId = (id) => api.get(`survey/user-surveys/${id}`);
  const getSurveyWithId = (id) => api.get(`survey/${id}`);
  const deleteSurvey = (id) => api.delete(`survey/delete-survey/${id}`);
  const editSurvey = ({ payload, id }) =>
    api.put(`survey/edit-survey/${id}`, payload);

  const createSurvey = (payload) => api.post("survey/add-survey", payload);
  const addSurveyResponse = (payload) => api.post("survey/add-survey-response", payload);
  const addSurveyQuestion = (payload) => api.post("survey/add-question", payload);
  const editSurveyQuestion = ({id,payload}) => api.put(`survey/edit-question/${id}`, payload);
  const removeSurveyQuestion = (id) => api.delete(`survey/remove-question/${id}`);

  return {
    survey: {
      getUserSurveysWithId,
      createSurvey,
      getSurveyResults,
      getSurveys,
      getSurveyWithId,
      deleteSurvey,
      editSurvey,
      getUserSurveys,
      addSurveyResponse,
      addSurveyQuestion,
      editSurveyQuestion,
      removeSurveyQuestion
    },
  };
}
