export default function (/**@type {ApisauceInstance} */ api) {
  const getAllProducts = (paging) => api.get(`product`, paging);
  const getProductWithId = (id) => api.get(`product/admin/${id}`);
  const addProduct = (payload) => api.post(`product`, payload);
  const editProduct = ({ payload, id }) => api.put(`product/${id}`, payload);
  const saveProductCityOffers = (payload) => api.put(`product/save-product-city-offers`, payload);
  const deleteProduct = (id) => api.delete(`product/${id}`);
  const deleteProductCityOffers = (payload) => api.put(`product/remove-product-city-offers`,payload);


  return {
    products: {
      getAllProducts,
      getProductWithId,
      addProduct,
      editProduct,
      deleteProduct,
      deleteProductCityOffers,
      saveProductCityOffers
    },
  };
}
