import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";
import { Trans } from "@lingui/react";
const apiNamespace = "outlets";

const getAllOutletsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_OUTLETS,
});
const getOutletWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_OUTLET_WITH_ID,
});

const addOutletLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_OUTLET,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/outlet/list"));
  }
});
const uploadOutletsLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPLOAD_OUTLETS,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/outlet/list"));
  }
});

const editOutletLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_OUTLET,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/outlet/list"));
  }
});

const deleteOutletLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_OUTLET,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllOutletsLogic,
  getOutletWithIdLogic,
  addOutletLogic,
  editOutletLogic,
  deleteOutletLogic,
  uploadOutletsLogic
];
