import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "survey";
const getSurveyWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SURVEY_WITH_ID,
});
const getSurveysLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SURVEYS,
});
const getSurveyResultsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SURVEY_RESULTS,
});
const getUserSurveysLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_USER_SURVEYS,
});
const getUserSurveysWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_USER_SURVEYS_WITH_ID,
});
const createSurveyLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_SURVEY,
  successCb: (dispatch) => {
    dispatch(push("/survey/survey-management/list"));
  },
});
const addSurveyResponseLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_SURVEY_RESPONSE,
});
const addSurveyQuestionLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_SURVEY_QUESTION,
  showErrorMessage: true,
  showSuccessMessage: true,
  successMessage: "تم اضافة السؤال بنجاح",
});
const editSurveyQuestionLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_SURVEY_QUESTION,
  showErrorMessage: true,
  showSuccessMessage: true,
  successMessage: "تم تعديل السؤال بنجاح",
});
const removeSurveyQuestionLogic = logic(apiNamespace, {
  actionName: ActionTypes.REMOVE_SURVEY_QUESTION,
  showErrorMessage: true,
  showSuccessMessage: true,
  successMessage: "تم حذف السؤال بنجاح",
});

const editSurveyLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_SURVEY,
  successCb: (dispatch) => {
    dispatch(push("/survey/survey-management/list"));
  },
});
const deleteSurveyLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_SURVEY,
});

export default [
  createSurveyLogic,
  getSurveysLogic,
  deleteSurveyLogic,
  getSurveyWithIdLogic,
  editSurveyLogic,
  addSurveyResponseLogic,
  getUserSurveysLogic,
  getUserSurveysWithIdLogic,
  addSurveyQuestionLogic,
  editSurveyQuestionLogic,
  removeSurveyQuestionLogic,
  getSurveyResultsLogic,
];
