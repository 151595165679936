
import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { Trans } from "@lingui/react";

const apiNamespace = "outletType";

const getAllOutletTypesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_OUTLET_TYPES,
});
const getOutletTypeWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_OUTLET_TYPE_WITH_ID,
});
const createOutletTypeLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_OUTLET_TYPE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});
const updateOutletTypeLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_OUTLET_TYPE,
});
const deleteOutletTypeLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_OUTLET_TYPE,
});

export default [
  getAllOutletTypesLogic,
  getOutletTypeWithIdLogic,
  createOutletTypeLogic, 
  updateOutletTypeLogic,
  deleteOutletTypeLogic
];
  
  