export default function (/**@type {ApisauceInstance} */ api) {
  
    const getAllOutletTypes = (paging) => api.get(`outletType`, paging);
    const getOutletTypeWithId = (id) => api.get(`outletType/${id}`);
    const createOutletType = (payload) => api.post(`outletType`, payload);
  
    const updateOutletType = ({ payload, id }) => api.put(`outletType/${id}`, payload);
  
    const deleteOutletType = (id) => api.delete(`outletType/${id}`);
  
   
  
    return {
        outletType: {
            getAllOutletTypes,
            getOutletTypeWithId,
            createOutletType,
            updateOutletType,
            deleteOutletType,
      },
    };
  }
  