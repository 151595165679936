// import { createReducer } from "utils/reduxsauce";
import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState(
    "supervisors",
    {
      metadata: undefined,
      selectedSupervisor: undefined,
      openDialog: false,
      openDeleteDialog: false,
    },
    []
  )
};

const supervisorCreatedSuccess = (state, draft, payload) => {
  const supervisors = state.supervisors || [];
  draft.supervisors = [...supervisors, payload];

  draft.openDialog = false;
};
const supervisorDeleteSuccess = (state, draft, payload) => {
  const supervisors = state.supervisors || [];
  draft.supervisors = supervisors.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};


const supervisorEditedSuccess = (state, draft, payload) => {
  const supervisors = state.supervisors || [];
  var supervisorIndex = supervisors.findIndex((u) => u.id === payload.id);
  draft.supervisors[supervisorIndex] = payload;
  draft.openDialog = false;
};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};

const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.supervisor_errors = [];
};
export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "deleteSupervisor",
    stateName: "supervisor",
    async: true,
    isPaginated: false,
    successCb: supervisorDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "setSupervisor",
    stateName: "supervisor",
    async: false,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  
  ...bindReducers(ActionTypes, {
    action: "getAllSupervisors",
    stateName: "supervisors",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getSupervisor",
    stateName: "supervisor",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "addSupervisor",
    stateName: "supervisor",
    async: true,
    isPaginated: false,
    successCb: supervisorCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editSupervisor",
    stateName: "supervisor",
    async: true,
    isPaginated: false,
    successCb: supervisorEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: true,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: true,
    successCb: resetDialog,
  })
});
