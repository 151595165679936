export default function (/**@type {ApisauceInstance} */ api) {
  const getAllPrizes = (paging) => api.get(`Prize/prizes`, paging);

  const addPrize = (payload) => api.post(`Prize/add-prize`, payload);

  const editPrize = ({ payload, id }) =>
    api.put(`Prize/edit-prize/${id}`, payload);

  const deletePrize = (id) => api.delete(`Prize/delete-prize/${id}`);

  ////outlet-prize
  const getAllOutletPrizes = (paging) => api.get(`OutletPrize/prizes`, paging);

  const addOutletPrize = (payload) =>
    api.post(`OutletPrize/add-prize`, payload);

  const editOutletPrize = ({ payload, id }) =>
    api.put(`OutletPrize/edit-prize/${id}`, payload);

  const deleteOutletPrize = (id) =>
    api.delete(`OutletPrize/delete-prize/${id}`);

  return {
    prizes: {
      getAllPrizes,
      addPrize,
      editPrize,
      deletePrize,
      /////
      getAllOutletPrizes,
      addOutletPrize,
      editOutletPrize,
      deleteOutletPrize,
    },
  };
}
