import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllNationalities: { args: ["payload"], meta: { async: true } },
    addNationality: { args: ["payload"], meta: { async: true } },
    editNationality: { args: ["payload"], meta: { async: true } },
    deleteNationality: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/Nationalities/",
  }
);
export const ActionTypes = Types;
export default Creators;
