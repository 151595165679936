import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllOutlets: { args: ["payload"], meta: { async: true } },
    getOutletWithId: { args: ["payload"], meta: { async: true } },
    addOutlet: { args: ["payload"], meta: { async: true } },
    uploadOutlets: { args: ["payload"], meta: { async: true } },
    editOutlet: { args: ["payload"], meta: { async: true } },
    deleteOutlet: { args: ["payload"], meta: { async: true } },

    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/outlets/",
  }
);
export const ActionTypes = Types;
export default Creators;
