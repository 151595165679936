import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("products", { metadata: undefined }, []),
};

const productDeleteSuccess = (state, draft, payload) => {
  const products = state.products || [];
  draft.products = products.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};
const deleteProductCityOffersSuccess=(state, draft, payload) => {
  //debugger
  draft.product.cityOffers = state.product.cityOffers.filter((a) => a.cityId != payload.data);
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllProducts",
    stateName: "products",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getProductWithId",
    stateName: "product",
    async: true,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "addProduct",
    stateName: "product",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "editProduct",
    stateName: "product",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteProduct",
    stateName: "product",
    async: true,
    isPaginated: false,
    successCb: productDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteProductCityOffers",
    stateName: "productCityOffer",
    async: true,
    isPaginated: false,
    successCb:deleteProductCityOffersSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "saveProductCityOffers",
    stateName: "productCityOffer",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "setProduct",
    stateName: "product",
    async: false,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
