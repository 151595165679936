
  
import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("channels", { metadata: undefined }, []),
  ...defaultState("channel", { metadata: undefined }, {}),
};
const channelEditedSuccess = (state, draft, payload) => {
  const channels = state.channels || [];
  var channelIndex = channels.findIndex((u) => u.id === payload.id);
  draft.channels[channelIndex] = payload;
  draft.openDialog = false;
};

const channelCreatedSuccess = (state, draft, payload) => {
  const channels = state.channels || [];
  draft.channels = [...channels, payload];
  draft.openDialog = false;
};

const channelDeleteSuccess = (state, draft, payload) => {
  const channels = state.channels || [];
  draft.channels = channels.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.channel_errors = [];
};

export const reducer = createReducer(initialState, {         
  ...bindReducers(ActionTypes, {
     action: "getAllChannels",
     stateName: "channels",
     async: true,
     isPaginated: true
  }),
  ...bindReducers(ActionTypes, {
     action: "getChannelWithId",
     stateName: "channel",
     async: true,
     isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "createChannel",
    stateName: "channel",
    async: true,
    isPaginated: false,
    successCb:channelCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
     action: "updateChannel",
     stateName: "channel",
     async: true,
     isPaginated: false,
     successCb:channelEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteChannel",
    stateName: "channel",
    async: true,
    isPaginated: false,
    successCb:channelDeleteSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
  