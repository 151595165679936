
import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { Trans } from "@lingui/react";
const apiNamespace = "channel";

const getAllChannelsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_CHANNELS,
});
const getChannelWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CHANNEL_WITH_ID,
});
const createChannelLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_CHANNEL,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});
const updateChannelLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_CHANNEL,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});
const deleteChannelLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_CHANNEL,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllChannelsLogic,
  getChannelWithIdLogic,
  createChannelLogic, 
  updateChannelLogic,
  deleteChannelLogic
];
  
  