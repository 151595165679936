export default function (/**@type {ApisauceInstance} */ api) {
  const getAllAttendances = (paging) =>
    api.get(`Attendance/get-attendances`, paging);

  return {
    attendances: {
      getAllAttendances,
    },
  };
}
