// import { createReducer } from "utils/reduxsauce";
import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState(
    "users",
    {
      metadata: undefined,
      selectedUser: undefined,
      openDialog: false,
      openDeleteDialog: false,
    },
    []
  ),
  ...defaultState(
    "roles",
    { metadata: undefined, selectedUser: undefined },
    []
  ),
};

const userCreatedSuccess = (state, draft, payload) => {
  const users = state.users || [];
  draft.users = [...users, payload];

  draft.openDialog = false;
};
const userDeleteSuccess = (state, draft, payload) => {
  const users = state.users || [];
  draft.users = users.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};
const roleDeleteSuccess = (state, draft, payload) => {
  const roles = state.roles || [];
  draft.roles = roles.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};

const roleCreatedSuccess = (state, draft, payload) => {
  const roles = state.roles || [];
  draft.roles = [...roles, payload];

  // draft.openDialog = false;
};

const userEditedSuccess = (state, draft, payload) => {
  const users = state.users || [];
  var userIndex = users.findIndex((u) => u.id === payload.id);
  draft.users[userIndex] = payload;
  draft.openDialog = false;
};
const roleEditedSuccess = (state, draft, payload) => {
  const roles = state.roles || [];
  var roleIndex = roles.findIndex((u) => u.id === payload.id);
  draft.roles[roleIndex] = payload;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};

const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.user_errors = [];
};
export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "deleteUser",
    stateName: "user",
    async: true,
    isPaginated: false,
    successCb: userDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "getUsers",
    stateName: "users",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "setUser",
    stateName: "user",
    async: false,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getUser",
    stateName: "user",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createUser",
    stateName: "user",
    async: true,
    isPaginated: false,
    successCb: userCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "createUsers",
    stateName: "users",
    async: true,
    isPaginated: false,
    successCb: userCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "createAppUser",
    stateName: "user",
    async: true,
    isPaginated: false,
    successCb: userCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "createRole",
    stateName: "role",
    async: true,
    isPaginated: false,
    successCb: roleCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editUser",
    stateName: "user",
    async: true,
    isPaginated: false,
    successCb: userEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editRole",
    stateName: "role",
    async: true,
    isPaginated: false,
    successCb: roleEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "getRoles",
    stateName: "roles",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getRole",
    stateName: "role",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getPermissions",
    stateName: "permissions",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: true,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: true,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteRole",
    stateName: "role",
    async: true,
    isPaginated: false,
    successCb: roleDeleteSuccess,
  }),
});
