import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";
const apiNamespace = "brands";

const getAllBrandsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_BRANDS,
});
const getBrandWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_BRAND_WITH_ID,
});
const addBrandLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_BRAND,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

const editBrandLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_BRAND,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deleteBrandLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_BRAND,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllBrandsLogic,
  getBrandWithIdLogic,
  addBrandLogic,
  editBrandLogic,
  deleteBrandLogic,
];
