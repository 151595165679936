import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("nationalities", { metadata: undefined }, []),
  ...defaultState(
    "nationality",
    { metadata: undefined },
    {
      products: [],
    }
  ),
};


const nationalityEditedSuccess = (state, draft, payload) => {
  const nationalities = state.nationalities || [];
  var nationalityIndex = nationalities.findIndex((u) => u.id === payload.id);
  draft.nationalities[nationalityIndex] = payload;
  draft.openDialog = false;

};

const nationalityCreatedSuccess = (state, draft, payload) => {
  // debugger
  const nationalities = state.nationalities || [];
  draft.nationalities = [...nationalities, payload];
  draft.openDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

const nationalityDeleteSuccess = (state, draft, payload) => {
  const nationalities = state.nationalities || [];
  draft.nationalities = nationalities.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};




export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllNationalities",
    stateName: "nationalities",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "addNationality",
    stateName: "nationality",
    async: true,
    isPaginated: false,
    successCb:nationalityCreatedSuccess
    
  }),
  ...bindReducers(ActionTypes, {
    action: "editNationality",
    stateName: "nationality",
    async: true,
    isPaginated: false,
    successCb:nationalityEditedSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteNationality",
    stateName: "nationality",
    async: true,
    isPaginated: false,
    successCb:nationalityDeleteSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),

});
